<template>
  <div>
    <Card>
      <template #body>
        <v-data-table
          class="table"
          item-key="id"
          mobile-breakpoint="0"
          loading
          :loading-text="$t('table.there is no data')"
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="items"
          :hide-default-footer="true"
          :items-per-page="
            pagination ? paginate.itemsPerPage : noSSRPagesPerRow
          "
          :page.sync="paginate.page"
          :custom-filter="filterOnlyCapsText"
          @page-count="pageCount = $event"
        >
          <v-progress-linear
            v-show="loading"
            slot="progress"
            color="#596e75"
            indeterminate
          ></v-progress-linear>
          <template v-slot:[`item.contractor`]="{ item }">
            <v-avatar size="24" class="me-2 bg-gradient-default">
              <span class="text-white text-xs">{{
                item.contractor.name[0]
              }}</span>
            </v-avatar>
            <span class="text-xs">{{ item.contractor.name }}</span>
          </template>

          <template v-slot:[`item.assign_employee`]="{ item }">
            <v-btn
              v-if="
                str_per.indexOf('project-assign-employee') > -1 &&
                item.status_id != 3
              "
              :ripple="false"
              icon
              link
              :to="`projects/assign-employee/${item.id}`"
              width="34px"
              height="34px"
              class="me-4"
            >
              {{ classBtn("d-block") }}
              <v-icon>mdi-account-arrow-left</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.daily_entry`]="{ item }">
            <v-btn
              :ripple="false"
              icon
              link
              :to="`/projects/daily-entry/${item.id}`"
              width="34px"
              height="34px"
              class="me-4 text-xs"
            >
              <v-icon> mdi-face-man</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.project_name`]="{ item }">
            <v-btn
              :ripple="false"
              icon
              link
              :to="`/projects/details/${item.id}`"
              width="34px"
              height="34px"
              class="me-4 text-xs"
              >{{ item.project_name }}</v-btn
            >
          </template>

          <template v-slot:[`item.status_project`]="{ item }">
            <div>
              <v-icon
                size="15"
                class="mx-1"
                :class="color_project(item.status_id)"
              >
                {{ icon_project(item.status_id) }}
              </v-icon>
              <span :class="color_project(item.status_id)">{{
                item.status.status_lang
              }}</span>
            </div>
          </template>

          <template v-slot:[`item.btns`]="{ item }">
            <div :id="item.id" :class="chack_permission">
              <v-menu top :close-on-click="closeOnClick">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="dark" small v-bind="attrs" v-on="on" icon>
                    <v-icon size="20">mdi-chevron-triple-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <slot name="action-table" :item="item">
                    <v-list-item v-for="(btn, index) in btns" :key="index">
                      <template v-if="str_per.indexOf(btn.permission) > -1">
                        {{ classBtn("d-block") }}
                        <v-list-item-title class="my-3">
                          <a
                            v-if="btn.url"
                            style="color: #67748e"
                            class="text-decoration-none"
                            :href="btn.item ? btn.url + item.id : btn.url"
                          >
                            <v-icon v-if="btn.type == 'icon'">{{
                              btn.icon
                            }}</v-icon>
                            {{ $t("general." + btn.text.replace("_", " ")) }}
                          </a>
                          <span
                            style="cursor: pointer"
                            v-else
                            @click="action(item, btn.text)"
                          >
                            <v-icon v-if="btn.type == 'icon'">{{
                              btn.icon
                            }}</v-icon>
                            {{ $t("general." + btn.text.replace("_", " ")) }}
                          </span>
                        </v-list-item-title>
                      </template>
                    </v-list-item>
                    <!-- stoped -->
                    <v-list-item
                      v-if="
                        str_per.indexOf('project-stop') > -1 &&
                        item.status_id == 1
                      "
                    >
                      {{ classBtn("d-block") }}

                      <v-list-item-title class="my-3">
                        <span
                          style="color: #67748e; cursor: pointer"
                          class="text-decoration-none"
                          @click="change_status(item, 'stop')"
                        >
                          <v-icon>mdi-stop-circle-outline</v-icon>
                          {{ $t("project.stop") }}
                        </span>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- complete -->
                    <v-list-item
                      v-if="
                        str_per.indexOf('project-complete') > -1 &&
                        item.status_id == 1
                      "
                    >
                      {{ classBtn("d-block") }}

                      <v-list-item-title class="my-3">
                        <span
                          style="color: #67748e; cursor: pointer"
                          class="text-decoration-none"
                          @click="change_status(item, 'complete')"
                        >
                          <v-icon>mdi-check</v-icon>
                          {{ $t("project.complete") }}
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </slot>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
        <div class="py-3" id="pagination_table">
          <div class="d-flex justify-md-space-between mb-3 align-center">
            <div style="width: 300px" class="d-flex align-center">
              <select-pre-page
                @changeItemPage="changeItemPage"
              ></select-pre-page>
            </div>
            <paginationComponent
              @handlePageChange="handlePageChange"
            ></paginationComponent>
          </div>
        </div>
      </template>
    </Card>
    <!-- <daily-entry-modal :open="open" :item="item"></daily-entry-modal> -->
  </div>
</template>
  <script>
import { mapActions, mapGetters } from "vuex";
import Card from "../Components/Card.vue";
import ProjectService from "../../../services/project.service";
// import Daily_entry_modal from "./daily_entry_modal";
import tableMixin from "../../../mixin/table";
import selectPrePage from "../Components/seletPrePage";
import paginationComponent from "../Components/Pagination";
import progressLoading from "../Components/progressLoading";
export default {
  mixins: [tableMixin],
  name: "Dashboard",
  components: {
    Card,
    // Daily_entry_modal,
    selectPrePage,
    paginationComponent,
    progressLoading,
    // Modal,
  },
  data() {
    return {
      modal_data: {
        size: "600px",
        title: this.$i18n.t("role.Edit"),
      },
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          url: "/projects/edit/",
          item: true,
          permission: "project-edit",
        },
        {
          type: "icon",
          text: "delete",
          color: "bg-gradient-success",
          icon: "mdi-delete",
          permission: "project-delete",
        },
      ],
      header: [
        {
          text: this.$i18n.t("project.project_name"),
          value: "project_name",
          align: "center",
        },
        // {
        //   text: this.$i18n.t("project.address_project"),
        //   value: "address_project",
        //   align: "center",
        // },
        // {
        //   text: this.$i18n.t("project.number_of_hour"),
        //   value: "number_of_hour",
        //   align: "center",
        // },

        // {
        //   text: this.$i18n.t("project.number_of_employee"),
        //   value: "number_of_employee",
        //   align: "center",
        // },
        {
          text: this.$i18n.t("project.contractor"),
          value: "contractor",
          align: "start",
        },
        {
          text: this.$i18n.t("project.status"),
          value: "status_project",
          align: "center",
        },
        {
          text: this.$i18n.t("project.assign_employee"),
          value: "assign_employee",
          align: "center",
        },
        {
          text: this.$i18n.t("project.daily_entry"),
          value: "daily_entry",
          align: "center",
        },
        { text: this.$i18n.t("Action"), value: "btns", align: "center" },
      ],
      card: {
        title: this.$i18n.t("Projects"),
        add_url: "/projects/create",
        permission: "project-create",
      },
      open: false,
      item: null,
      load_excel: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["str_per"]),
    classLangBtn() {
      return {
        "mr-6": this.$vuetify.rtl,
        "ml-6": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    ...mapActions("project", ["getData"]),
    color_project(val) {
      if (val == 1) return "text-info";
      if (val == 2) return "text-danger";
      if (val == 3) return "text-success";
    },
    icon_project(val) {
      if (val == 1) return "mdi-home-clock-outline";
      if (val == 2) return "mdi-stop";
      if (val == 3) return "mdi-check";
    },
    changeItemPage(page) {
      this.paginate.page = page;
      this.getData();
    },

    handlePageChange(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.getData({ page, type: "pre_page" });
    },
    classBtn(val) {
      return this.$store.commit("table/SET_CHECK_PER", val);
    },
    openemployee(item) {
      this.open = true;
      this.item = item;
    },
    change_status(item, string) {
      if (string == "stop") {
        var status_id = 2;
        var message = this.$i18n.t("project.Are you sure to stop project?");
      } else {
        var status_id = 3;
        var message = this.$i18n.t("project.Are you sure to complete project?");
      }

      this.$swal({
        title: message,
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("Yes"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          return ProjectService.change_status(item.id, {
            status_id: status_id,
          }).then(
            (response) => {
              item.status_id = status_id;
              item.status.status_lang = response.data.data.status.status_lang;
              this.$swal.fire(
                this.$i18n.t("Done!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("Error"),
                this.$i18n.t("There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("Cancelled Proccess"),
            "error"
          );
        }
      });
    },
    set_data() {
      this.$store.commit("SET_CARD", this.card);
      this.$store.commit("SET_COLLECTION", "project");
      this.$store.commit("table/SET_LOADING", true);
      this.$store.commit("table/SET_HEADERS", this.header);
      // this.$store.commit("table/SET_BTNS", this.btns);
      this.$store.commit("table/SET_PAGINATION", true);
    },
  },
  created() {
    console.log(this.$route.params.message);
    if (this.$route.params.message) {
      this.$store.commit("form/SET_NOTIFY", this.$route.params.message);
    }
  },
  mounted() {
    // this.getUsers();
    this.set_data();
    this.getData({ reset: false }).then(
      (res) => {
        //   this.add_form[0].items = res.data.camps;
        //   this.form = res.data.forms[0];
      },
      (err) => {}
    );
    document.title = this.$i18n.t("Projects");
  },
};
</script>
  