<template>
  <div>
    <div class="d-flex pl-3 rows_per_page">
      <label for="" style="font-size: 14px" class="font-weight-bold mx-3 mt-2">
        {{ $t("table.Rows Per Page") }}
      </label>
      <v-select
        v-if="pagination"
        style="width: 100px"
        v-model="paginate.itemsPerPage"
        hide-details
        @change="$emit('changeItemPage', $event)"
        :items="[5, 10, 15, 50, 100]"
        dense
      ></v-select>

      <v-select
        v-else
        style="width: 100px"
        v-model="noSSRPagesPerRow"
        hide-details
        @change="changeNoSSRItemsPerPage"
        :items="[10, 20, 30, 50, 100]"
        dense
      ></v-select>
      <span class="font-weight-bold mx-3 mt-2">
        {{ $t("table.from") }}
        <span style="font-size: 17px" class="mr-2">
          {{ paginate.total }}
        </span>
      </span>
    </div>
  </div>
</template>
<script>
import tableMixin from "../../../mixin/table";
export default {
  mixins: [tableMixin],
};
</script>