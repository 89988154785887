<template>
  <div>
    <v-pagination
      v-if="pagination"
      prev-icon="fa fa-angle-left"
      next-icon="fa fa-angle-right"
      class="pagination"
      color="#06ab95"
      v-model="paginate.page"
      :length="pages"
      @input="$emit('handlePageChange', page)"
      total-visible="5"
      circle
    ></v-pagination>
    <v-pagination
      v-else
      prev-icon="fa fa-angle-left"
      next-icon="fa fa-angle-right"
      class="pagination"
      color="#06ab95"
      v-model="paginate.page"
      :length="noSSRPages"
      @input="handleNoSSRPageChange"
      total-visible="5"
      circle
    ></v-pagination>
  </div>
</template>
<script>
import tableMixin from "../../../mixin/table";
export default {
  mixins: [tableMixin],
};
</script>