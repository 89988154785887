import { mapState } from "vuex";

export default {
    data() {
        return {

            search: "",
            closeOnClick: true,
            selected: [],
            noSSRPagesPerRow: 10,
            // bulk_selected: [],
            // items: [],
            // headers: [],
            // data_extend: {},
            // data_expand: {},
            // paginate: {
            //     page: 1,
            //     itemsPerPage: 0,
            //     total: 0,
            // },
            // pagination: true,
            // select_view: false,
        };

    },
    computed: {

        ...mapState("table", [
            "bulk_selected",
            "btn_table",
            "items",
            "headers",
            // "btns",
            "loading",
            "data_extend",
            "data_expand",
            "paginate",
            "pagination",
            "select_view",
            "chack_permission",
        ]),
        pages() {
            return Math.ceil(this.paginate.total / this.paginate.itemsPerPage) | 0;
        },
        noSSRPages() {
            return Math.ceil(this.items.length / this.noSSRPagesPerRow) | 0;
        },
    },
    watch: {
        selected($event) {

        },
    },
    methods: {
        handleNoSSRPageChange(page) {
            this.paginate.page = page;
        },
        changeNoSSRItemsPerPage($eve) {
            this.noSSRPagesPerRow = parseInt($eve);
        },

        filterOnlyCapsText(value, search, item) {
            console.log(value);
            console.log(search);
            return (
                value != null &&
                search != null &&
                // typeof value === "string" &&
                value.toString().toLocaleLowerCase().indexOf(search) !== -1
            );
        },
    },
}