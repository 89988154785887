var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-data-table',{staticClass:"table",attrs:{"item-key":"id","mobile-breakpoint":"0","loading":"","loading-text":_vm.$t('table.there is no data'),"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"hide-default-footer":true,"items-per-page":_vm.pagination ? _vm.paginate.itemsPerPage : _vm.noSSRPagesPerRow,"page":_vm.paginate.page,"custom-filter":_vm.filterOnlyCapsText},on:{"update:page":function($event){return _vm.$set(_vm.paginate, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.contractor",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"me-2 bg-gradient-default",attrs:{"size":"24"}},[_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(item.contractor.name[0]))])]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item.contractor.name))])]}},{key:"item.assign_employee",fn:function(ref){
var item = ref.item;
return [(
              _vm.str_per.indexOf('project-assign-employee') > -1 &&
              item.status_id != 3
            )?_c('v-btn',{staticClass:"me-4",attrs:{"ripple":false,"icon":"","link":"","to":("projects/assign-employee/" + (item.id)),"width":"34px","height":"34px"}},[_vm._v(" "+_vm._s(_vm.classBtn("d-block"))+" "),_c('v-icon',[_vm._v("mdi-account-arrow-left")])],1):_vm._e()]}},{key:"item.daily_entry",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"me-4 text-xs",attrs:{"ripple":false,"icon":"","link":"","to":("/projects/daily-entry/" + (item.id)),"width":"34px","height":"34px"}},[_c('v-icon',[_vm._v(" mdi-face-man")])],1)]}},{key:"item.project_name",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"me-4 text-xs",attrs:{"ripple":false,"icon":"","link":"","to":("/projects/details/" + (item.id)),"width":"34px","height":"34px"}},[_vm._v(_vm._s(item.project_name))])]}},{key:"item.status_project",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('v-icon',{staticClass:"mx-1",class:_vm.color_project(item.status_id),attrs:{"size":"15"}},[_vm._v(" "+_vm._s(_vm.icon_project(item.status_id))+" ")]),_c('span',{class:_vm.color_project(item.status_id)},[_vm._v(_vm._s(item.status.status_lang))])],1)]}},{key:"item.btns",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:_vm.chack_permission,attrs:{"id":item.id}},[_c('v-menu',{attrs:{"top":"","close-on-click":_vm.closeOnClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"dark","small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-chevron-triple-down")])],1)]}}],null,true)},[_c('v-list',[_vm._t("action-table",function(){return [_vm._l((_vm.btns),function(btn,index){return _c('v-list-item',{key:index},[(_vm.str_per.indexOf(btn.permission) > -1)?[_vm._v(" "+_vm._s(_vm.classBtn("d-block"))+" "),_c('v-list-item-title',{staticClass:"my-3"},[(btn.url)?_c('a',{staticClass:"text-decoration-none",staticStyle:{"color":"#67748e"},attrs:{"href":btn.item ? btn.url + item.id : btn.url}},[(btn.type == 'icon')?_c('v-icon',[_vm._v(_vm._s(btn.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("general." + btn.text.replace("_", " ")))+" ")],1):_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.action(item, btn.text)}}},[(btn.type == 'icon')?_c('v-icon',[_vm._v(_vm._s(btn.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("general." + btn.text.replace("_", " ")))+" ")],1)])]:_vm._e()],2)}),(
                      _vm.str_per.indexOf('project-stop') > -1 &&
                      item.status_id == 1
                    )?_c('v-list-item',[_vm._v(" "+_vm._s(_vm.classBtn("d-block"))+" "),_c('v-list-item-title',{staticClass:"my-3"},[_c('span',{staticClass:"text-decoration-none",staticStyle:{"color":"#67748e","cursor":"pointer"},on:{"click":function($event){return _vm.change_status(item, 'stop')}}},[_c('v-icon',[_vm._v("mdi-stop-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t("project.stop"))+" ")],1)])],1):_vm._e(),(
                      _vm.str_per.indexOf('project-complete') > -1 &&
                      item.status_id == 1
                    )?_c('v-list-item',[_vm._v(" "+_vm._s(_vm.classBtn("d-block"))+" "),_c('v-list-item-title',{staticClass:"my-3"},[_c('span',{staticClass:"text-decoration-none",staticStyle:{"color":"#67748e","cursor":"pointer"},on:{"click":function($event){return _vm.change_status(item, 'complete')}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.$t("project.complete"))+" ")],1)])],1):_vm._e()]},{"item":item})],2)],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","color":"#596e75","indeterminate":""},slot:"progress"})],1),_c('div',{staticClass:"py-3",attrs:{"id":"pagination_table"}},[_c('div',{staticClass:"d-flex justify-md-space-between mb-3 align-center"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"300px"}},[_c('select-pre-page',{on:{"changeItemPage":_vm.changeItemPage}})],1),_c('paginationComponent',{on:{"handlePageChange":_vm.handlePageChange}})],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }